
import {Vue, Component, Prop, VModel} from 'vue-property-decorator'

import {TPolicyProgramm} from '@/types/api'

export type TDataRatioOption = TPolicyProgramm

@Component
export default class DataRadio extends Vue {
  @VModel({ type: String }) modelValue!: string

  @Prop({type: Array, default: () => []})
  readonly options: TDataRatioOption[]

  onClick (value: string) {
    this.modelValue = value
  }
}
