import 'normalize.css'
import '@/assets/scss/index.scss'

import Vue from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar'
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import mitt from 'mitt'

import ApiService from '@/api/ApiService'
import ErrorNotifierService from './api/ErrorNotifierService'

import { VueKeycloakOptions } from '@dsb-norge/vue-keycloak-js/dist/types'
import { GlobalEvents } from './types/mitt'

const api = new ApiService()
const emitter = mitt<GlobalEvents>()
const errorNotifier = new ErrorNotifierService(emitter)

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$mitt = emitter
Vue.prototype.$error = errorNotifier

Vue.use(VCalendar)
Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'login-required'
  },
  config: {
    url: process.env.VUE_APP_IDP_URL,
    realm: 'customers',
    clientId: process.env.VUE_APP_IDP_CLIENT_ID,
  },
  onReady: async (kc) => {
    await api.setToken(
      kc.token,
      kc.authenticated
    )

    new Vue({
      render: h => h(App)
    }).$mount('#app')
  },
  onAuthRefreshSuccess (kc) {
    api.setToken(
      kc.token,
      kc.authenticated
    )
  }
} as VueKeycloakOptions)
