export const formValidationErrors = {
  vin: 'Обязательное поле',
  ownerName: 'Обязательное поле',
  ownerKpp: 'Поле должно содержать 9 символов',
  ownerInn: 'Обязательное поле, от 10 до 12-ти символов',
  maker: 'Обязательное поле',
  model: 'Обязательное поле',
  ptsDate: 'Обязательное поле, дата должна быть валидной и не позже текущей даты',
  ptsNumber: 'Обязательное поле, от 10-и до 15-и символов',
  year: 'Обязательное поле, только числовые символы, минимальный год выпуска: 1900',
  weightNetto: 'Обязательное поле, только числовые символы',
  weightMax: 'Обязательное поле, только числовые символы, должна быть больше массы нетто',
  kasko: {
    cardId: 'Обязательное поле, только числовые символы',
    comment: 'Максимум 255 символов'
  }
}
