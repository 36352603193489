
import { Vue, Component, VModel, Emit, Prop } from 'vue-property-decorator'

import Modal from '@/components/UI/Modal.vue'
import DataRadio from '@/components/UI/DataRadio.vue'
import Button from '@/components/UI/Button.vue'

import type {TDataRatioOption} from '@/components/UI/DataRadio.vue'

@Component({
  components: {
    Modal,
    DataRadio,
    Button
  }
})
export default class ChangeIPModal extends Vue {
  @Prop({type: Array, default: () => []})
  readonly options: TDataRatioOption[]

  @VModel({ type: Boolean })
  open: boolean

  programm = ''

  get isSubmitDisabled () {
    return !this.programm
  }

  onCancelClick () {
    this.programm = ''
    this.open = false
  }

  onSubmitClick () {
    if (this.programm) {
      this.onSubmit(this.programm)
      this.open = false
    }
  }

  @Emit('submit')
  onSubmit (id: string) {
    return id
  }
}
