import {
  TAddSpecialCarDTO,
  TAddSpecialCarResponseData,
  TKaskoDTO,
  TKaskoLimitDTO,
  TKaskoLimitResponse,
  TPolicyProgramm,
  TPolicyRequestParams,
  TPolicyResponse,
  TSpecialCar,
  TSpecialCarData,
  TSpecialCarListDTO
} from '@/types/api'
import axios, { AxiosError, AxiosInstance, isAxiosError } from 'axios'
import { cloneDeep } from 'lodash'

import ErrorService from '@/api/ErrorService'
import { TError } from '@/types/error'
import { TErrorNotifierList } from '@/components/entities/ErrorNotifier/ErrorNotifier.vue'

export default class ApiService {
  private _instance: AxiosInstance
  private _token: string
  private _error: ErrorService

  constructor () {
    this._instance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      timeout: 2500,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this._instance.interceptors.response.use(_ => _, (error) => {
      if (isAxiosError(error)) {
        if (error.code === 'ERR_NETWORK') {
          return Promise.reject(this._error.getError(0))
        }

        const code = (error as AxiosError<TError, any>).response?.data.code
        const message = (error as AxiosError<TError, any>).response?.data.message

        return Promise.reject(
          code ?
          {name: '', code: code, title: 'Ошибка', message} :
          {name: '', code: null, title: 'Непредвиденная ошибка', message: 'Свяжитесь с менеджером для решения проблемы...'}
        )
      } else {
        return Promise.reject(this._error.getError(0))
      }
    })
  }

  public isSafeError (error: unknown): error is TErrorNotifierList {
    return (error as TErrorNotifierList)?.title !== undefined
      && typeof (error as TErrorNotifierList).title === 'string'
      && (error as TErrorNotifierList)?.message !== undefined
      && typeof (error as TErrorNotifierList).message === 'string'
  }

  setToken (value: string | undefined, authenticated: boolean | undefined) {
    if (!value) {
      return Promise.reject('Ошибка обновления токена')
    }

    if (!authenticated) {
      return Promise.reject('Пользователь не авторизован')
    }

    this._token = `Bearer ${value}`
    this._instance.interceptors.request.use((config) => {
      if (authenticated) {
        config.headers.Authorization = this._token
      }

      return config
    }, _ => Promise.reject(_))
  }

  getCards () {
    return this._instance.get('/cards')
  }

  getSpecialCars (params: TSpecialCarListDTO = {}) {
    return this._instance.get<TSpecialCarData>('/special-cars', { params })
  }

  createSpecialCars (dto: TAddSpecialCarDTO) {
    const _dto = cloneDeep(dto)

    _dto.kasko.cardId = Number((_dto.kasko.cardId as string).replace(/\s/g, ''))
    _dto.kasko.comment = _dto.kasko.comment.trim() ? _dto.kasko.comment : ' '
    _dto.year = Number(_dto.year)
    _dto.weightMax = Number(_dto.weightMax)
    _dto.weightNetto = Number(_dto.weightNetto)

    return this._instance.post<TAddSpecialCarResponseData>('/special-cars', _dto)
  }

  updateSpecialCars (id: string, dto: TSpecialCar) {
    return this._instance.put(`/special-cars/${id}`, dto)
  }

  deleteSpecialCars (id: string) {
    return this._instance.delete(`/special-cars/${id}`)
  }

  activateKaskoSpecialCars (id: string, policyProgramId: string) {
    return this._instance.post(`/special-cars/${id}/kasko/activate`, {
      disableAt: '',
      policyProgramId
    })
  }

  deactivateKaskoSpecialCars (id: string) {
    return this._instance.post(`/special-cars/${id}/kasko/deactivate`)
  }

  limitKaskoSpecialCars (id: string, dto: TKaskoLimitDTO) {
    return this._instance.post<TKaskoLimitResponse>(`/special-cars/${id}/kasko/limit-period`, dto)
  }

  updateKaskoSpecialCars (id: string, dto: TKaskoDTO) {
    return this._instance.put(`/special-cars/${id}/kasko`, dto)
  }

  getKaskoPolicies (params: TPolicyRequestParams = {}) {
    return this._instance.get<TPolicyResponse>('/kasko/policies', {
      params
    })
  }

  getPolicyProgramms () {
    return this._instance.get<TPolicyProgramm[]>('/kasko/insurance-programs')
  }
}
